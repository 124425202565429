// @import "tailwindcss/base";
// @import "tailwindcss/components";
// @import "tailwindcss/utilities";
@import "~bootstrap/scss/bootstrap.scss";


:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui;
    --bs-font-monospace: sfmono-regular, monospace;
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --body-bg: #e5edf4;
}

.jumbotron {
    // background-image: url("/frontend/vendors/images/header.webp");
    background-size: cover;
    height: 70vh;
}

html {
    font-style: normal;
}

body {
    font-family: "Inconsolata", monospace;
    font-style: normal;
    font-weight: 500;
}

.body-bg {
    background-color: var(--body-bg);
}

// override bootstrap border radius
.form-control {
    border-radius: 0.15rem;
    font-family: "Inconsolata", monospace;
}

.upload-form {
    margin-top: 2rem;
}

.br-big {
    border: 5px solid rgb(18 21 45);
}

.br-big-light {
    border: 5px solid rgb(36 46 96);
}

.br-small {
    border: 2px solid rgb(18 21 45);
}

h1,
h2,
h3,
h4 {
    font-family: "Kanit", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-style: normal;
}

.lead {
    font-weight: 400;
    font-size: 2rem;
}

.main-tagline {
    color: var(--bs-gray-300);
}

@keyframes icon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.icon-spin {
    animation: icon-spin 1s infinite linear;
}

// .btn {
//     background-color: initial;
//     border-color: initial;
// }

.btn-blue {
    @apply inline-block px-4 py-2;
    @apply font-semibold shadow-md;
    @apply bg-blue-700 text-white;
    @apply hover:bg-blue-400 focus:outline-none;
    @apply focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
}

.btn-block {
    @apply block w-full;
}

//#1d4ed8
.btn-primary {
    @apply inline-block px-4 py-2;
    @apply font-semibold rounded-1 shadow-md;
    @apply bg-yellow-500 text-dark outline-none;
    @apply border-2 border-yellow-500;
    @apply hover:bg-orange-700 hover:border-yellow-700;
    @apply focus:ring-2 focus:ring-yellow-400 focus:ring-opacity-75 focus:outline-none;
    @apply active:bg-yellow-700 active:border-yellow-900 #{!important};
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.home-section {
    margin: 8rem 0;
}

.section {
    margin: 4rem 0;
}

.bg-dark-blue {
    background-color: rgb(36 44 77);
}

.min-vh-60 {
    min-height: 60vh;
}

code {
    background-color: #f4f4f4;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 14px;
}
pre {
    background-color: #cacaca;
    padding: 1rem;
    border: 1px solid #3d2929;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
}
pre code {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
}